
export default class UtilsService {

    static formatData(val){
        return new Date(val).toLocaleDateString()
    }
    static formatDatabr(val){
        return new Date(val).toLocaleDateString()
    }

    static protectCPF(val){
        var c = val.split('');
        var protect = c.map((char,i)=>{
          // marcarar apenas indices maior que a 4ª pos e menor que a 11ª pos, a partir de um CPF Formatado com o filtro Vue formatCPF
          return (i > 3 && i < 11) ? '*' : char;
        })
        return protect.join('');
    }

    static formatCPF(val){
        if (val){
            var formattedCPF = val.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
            return formattedCPF;
        }
        return ''
    }

    static getWeekDay(val){
        return new Date(val).toLocaleDateString('pt-br',{weekday:'long'})
    }
    static formatreal(val) {
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val) 
    }
    static urlApi(){
         //return 'https://gssma.com.br/apps/';
         return process.env.NODE_ENV === 'production' ? 'https://gssma.com.br/apps':'http://localhost:8000';
    }
    static urlApp(){
        //return 'http://192.168.0.229:8080/'                                                                   // esse ip é do teu wifi? não esse é do servidor 
        return process.env.NODE_ENV === 'production' ? 'https://gssma.com.br':'https://localhost:8080/';

    }
}
// '' mostra o terminal de novo