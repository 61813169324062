import axios from 'axios';
import { router }  from '../routes'
import UtilsService  from '../service/utilsService'

const api = axios.create({

  baseURL: UtilsService.urlApi(),
  headers: { 
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+localStorage.getItem('tokenAccess'), 
  }
})

api.interceptors.response.use((response)=>{
  return response;
},
function(error){
  if(error.response !== undefined && error.response.status===401 || localStorage.getItem('tokenAccess')===null){
  
    localStorage.removeItem('tokenAccess');
    router.push('/login')
  }
  return error;

})
export default api;