import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router =  new Router({
  mode: 'history',
  routes:[
    { 
      path:'', 
      component: () => import('./components/Menu.vue'), 
      name:"menu" ,
      meta:{
        title:"SADT",
      }
    },
    { 
      path:'/login', 
      component: () => import('./components/Login.vue'), 
      name:"login" ,
      meta:{
        title:"SADT | login",
      }
    },
    /*
    { 
      path:'/logout', 
      component: () => import('./components/Logout.vue'), 
      name:"logout" ,
      meta:{
        title:"SADT | logout",

      }
    },
    { 
      path:'/aplicativos', 
      component: () => import('./components/Menu.vue'), 
      name:"apps_menu",
      meta:{
        title:"SADT | Aplicativos",
        requirestAuth: true
      }
    },
    { 
      path:'/mapa', 
      component: () => import('./components/mapa/Mapa.vue'), 
      name:"mapa",
      meta:{
        title:"SADT | Mapas",
        requirestAuth: true
      }
    },*/
    { 
      path:'/Cadastros', 
      component: () => import('./components/cadastros/Cadastros.vue'), 
      name:'cadastros',
      /*children:[
        {
          path:'troca-senha',
          component: () => import('./components/cadastros/componentes/equipe_multidisciplinar/TrocaSenha.vue'), 
          name:'troca-senha',
          meta:{
            title:"SADT | Trocar minha senha de usuario", requirestAuth: true
          },
        },{
        path:'edit',
        component: () => import('./components/cadastros/Edit.vue'), 
        name:'edite-usuario',
        meta:{
          title:"SADT | Edite Usuario", requirestAuth: true},
      }],*/
      meta:{
        title:"SADT | Meu usuario", requirestAuth: true
      }, 
    },
    { 
      path:'/evolucoes', 
      component: () => import('./components/evolucoes/Evolucoes.vue'), 
      name:'evolucoes',
      meta:{
        title:"SADT | Evoluções", requirestAuth: true
      }, 
    },
    { 
      path:'/notas-fiscais', 
      component: () => import('./components/notafiscal/NotaFiscal.vue'), 
      name:'notas-fiscais',
      meta:{
        title:"SADT | Nota Fiscal", requirestAuth: true
      }, 
    },
    { 
      path:'/atendimentos', 
      component: () => import('./components/atendimentos/Atendimentos.vue'), 
      name:'atendimentos',
      meta:{
        title:"SADT | Atendimentos", requirestAuth: true
      }, 
    },
    { 
      path:'/guias-sadt', 
      component: () => import('./components/guiasadt/GuiaSadt.vue'), 
      name:'guias-sadt',
      meta:{
        title:"SADT | Guia Sadt", requirestAuth: true
      }, 
    },
    { 
      path:'/relatorio-procucao', 
      component: () => import('./components/relatorioproducao/RelatorioProducao.vue'), 
      name:'relatorio-procucao',
      meta:{
        title:"SADT | Relatorio Producao", requirestAuth: true
      }, 
    },
    { 
      path:'/log', 
      component: () => import('./components/log/Log.vue'), 
      name:'log',
      meta:{
        title:"SADT | Log", requirestAuth: true
      }, 
    },
    { 
      path:'/acompanhamento-coleta-assinaturas', 
      component: () => import('./components/acompanhamento/Acompanhamento.vue'), 
      name:'acompanhamento-coleta-assinaturas',
      meta:{
        title:"SADT | Acompanhamento Coleta Assinatura", requirestAuth: true
      }, 
    },
    { 
      path:'/sessoes', 
      component: () => import('./components/sessoes/Sessoes.vue'), 
      name:'sessoes',
      meta:{
        title:"SADT | Evoluções", requirestAuth: true
      }, 
    },
    
    { 
      path:"/geolocalizacao/:id/:username", 
      component: () => import('./components/cadastros/componentes/pacientes/Geolocalizacao.vue'), 
      name:'geolocalizacao',
      meta:{
        title:"SADT | map", requirestAuth: true
      }, 
    },
    { 
      path:'/equipe_multi', 
      component: () => import('./components/frontmulti/MenuMulti.vue'), 
      name:'equipe_multi',
      meta:{
        title:"SADT | Equipe Mult", requirestAuth: true
      }, 
    },

    // Abaixo os links para as pastas da equipe Multiprofissional

    { 
      path:'/evolucao_equipe_multi', 
      component: () => import('./components/frontmulti/componentesEquipe/lancamentoEvolucao/evolucao.vue'), 
      name:'evolucao_equipe_multi',
      meta:{
        title:"SADT | Equipe Mult", requirestAuth: true
      }, 
    },
    { 
      path:'/coleta_assinatura', 
      component: () => import('./components/frontmulti/componentesEquipe/coletaAssinatura/coletaAssinatura.vue'), 
      name:'coleta_assinatura',
      meta:{
        title:"SADT | Equipe Mult", requirestAuth: true
      }, 
    },
    { 
      path:'/nota_fiscal_equipe', 
      component: () => import('./components/frontmulti/componentesEquipe/lancamentoNotaFiscal/notafiscalequipe.vue'), 
      name:'nota_fiscal_equipe',
      meta:{
        title:"SADT | Equipe Mult", requirestAuth: true
      }, 
    },
    { 
      path:'/prontuario_paciente', 
      component: () => import('./components/frontmulti/componentesEquipe/prontuarioPaciente/prontuario_paciente.vue'), 
      name:'prontuario_paciente',
      meta:{
        title:"SADT | Equipe Mult", requirestAuth: true
      }, 
    },
    { 
      path:'/producao_produtividade_equipe_multi.vue', 
      component: () => import('./components/frontmulti/componentesEquipe/produtividadeEquipeMulti/producao_produtividade_equipe_multi.vue'), 
      name:'producao_produtividade_equipe_multi.vue',
      meta:{
        title:"SADT | Equipe Mult", requirestAuth: true
      }, 
    },
    { 
      path:'/dadospessoais_equipe_multi', 
      component: () => import('./components/frontmulti/componentesEquipe/meusDados/dadospessoais_equipe_multi.vue'), 
      name:'dadospessoais_equipe_multi',
      meta:{
        title:"SADT | Equipe Mult", requirestAuth: true
      }, 
    },
    //{ path:'*', redirect: '/' }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.meta.requirestAuth){
    var usertoken = localStorage.getItem('tokenAccess')
    /*var usu = JSON.parse(localStorage.getItem('cfgu'))||{usuario:{data:{trocar_senha:''}}}
    console.log(usu.usuario.data.trocar_senha)*/
    if(usertoken){
      document.title = to.meta.title
      next()
    }else{
      document.title = to.meta.title
      next({name:'login'})
    }
  }
  next();
});

export { router };